<script>
export default {
	lang: 'shop',
	inject: ['getAppMessageDialog'],
	data() {
		return {
			order: {},
			loading: true,
			firstLoading: true,
			loadingDownload: false,
		}
	},
	trackPageView() {
		let url = this.$router.resolve({
			name: this.$route.name,
			params: {
				...this.$route.params,
				id: '__ID__',
			},
			query: this.$route.query,
		}).href
		return { location: url }
	},
	computed: {
		isPaid() {
			return this.order.paymentStatus == 'paid'
		},
		showPaymentDetails() {
			return this.order.paymentStatus != 'canceled' && !this.order.isSimple
		},
		showDeliveryDetails() {
			return this.order.deliveries.some((x) => x.status != 'canceled') && !this.order.isSimple
		},
		showDownloadFiles() {
			return this.order.dataBySeller.some((sellerData) => sellerData.invoiceFile)
		},
		canPay() {
			return this.order.paymentStatus == 'pending'
		},
	},
	methods: {
		async loadOrder() {
			await this.$shopApi.get({
				url: `/user/order/${this.$route.params.id}`,
				loading: (v) => (this.loading = v),
				onSuccess: async ({ data }) => {
					this.order = this.$assign({}, data.order)
					await this.updateOrderEvents()
				},
				onError: () => {
					return this.$router.replace({ name: 'user.orders' })
				},
			})
			this.firstLoading = false
		},
		async updateOrderEvents() {
			if (!this.order.confirmedEvt) {
				this.order.confirmedEvt = true
				await this.$shopApi.put(`/user/order/${this.order.id}/confirmed-evt`)
				this.$eventer().trigger('order:confirmed:view', { order: this.order })
			}
			if (this.order.paymentStatus == 'paid' && !this.order.paidEvt) {
				this.order.paidEvt = true
				await this.$shopApi.put(`/user/order/${this.order.id}/paid-evt`)
				this.$eventer().trigger('order:paid:view', { order: this.order })
			}
		},
		async downloadInvoiceFile(sellerData) {
			this.loadingDownload = true

			const response = await fetch(
				this.$shopApi.makeUrl(
					`/user/order/${this.order.id}/download-invoice-file/${sellerData.seller.id}`
				)
			)

			if (response.ok) {
				const blob = await response.blob()
				const blobURL = URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.setAttribute('href', blobURL)
				link.setAttribute('download', sellerData.invoiceFile)
				link.style.display = 'none'
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			} else {
				this.getAppMessageDialog().open({
					title: 'Error al descargar la factura',
					text: 'No se pudo descargar la factura, por favor contacta al vendedor para obtenerla.',
					dialogId: 'error-download-invoice',
				})
			}

			this.loadingDownload = false
		},
	},
	mounted() {
		this.loadOrder().then(() => {
			if (this.order) {
				this.$eventer().trigger('order:view', { order: this.order })
			}
		})
	},
	provide() {
		return {
			refreshOrder: () => this.loadOrder(),
		}
	},
}
</script>

<template>
	<UserpanelLayout :title="$lang('Detalle de compra')">
		<div v-if="loading && firstLoading" class="d-flex align-center justify-center py-16">
			<v-progress-circular size="50" indeterminate />
			<div class="ml-4">{{ 'Cargando el detalle de la compra' | lang }}</div>
		</div>
		<div v-else :class="{ disabled: loading && !firstLoading }">
			<CardLayout :color="order.statusesInfo.title.color" class="mb-6">
				<template #title>
					<div class="px-2 rounded" :class="`${order.statusesInfo.title.color} white--text`">
						{{ order.statusesInfo.title.buyerName || order.statusesInfo.title.name }}
					</div>
					<v-spacer />
					<v-menu v-if="showDownloadFiles" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<Button
								color="link"
								small
								text
								v-on="on"
								v-bind="attrs"
								:disabled="loadingDownload"
								:loading="loadingDownload"
							>
								<v-icon left>mdi-download</v-icon>
								{{ $lang('Descargar Facturas') }}
							</Button>
						</template>
						<v-list dense>
							<v-list-item
								v-for="sellerData of order.dataBySeller"
								:key="sellerData.seller.id"
								@click="downloadInvoiceFile(sellerData)"
								dense
							>
								<v-list-item-icon>
									<v-icon>mdi-file-download</v-icon>
								</v-list-item-icon>
								<v-list-item-title>{{ sellerData.seller.name }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
				<div>
					<div class="d-flex align-center">
						<div class="d-flex align-center">
							<v-icon class="mr-1">mdi-shopping</v-icon>
							<b>Compra #{{ order.code }}</b>
						</div>
						<div class="ml-4 d-flex align-center">
							<v-icon class="mr-1">mdi-clock</v-icon>
							{{ order.statusesAt.main.confirmed | datetime }}
						</div>
					</div>
				</div>
			</CardLayout>
			<template v-if="showPaymentDetails">
				<!-- discount coupon -->
				<UserOrderDetail-Discount :order="order" class="mb-6" />
				<!-- payment -->
				<v-row no-gutters>
					<v-col cols="12" sm="7">
						<!-- payment detail -->
						<UserOrderDetail-Payment :order="order" class="mb-6 mr-sm-6" />
					</v-col>
					<v-col cols="12" sm="5">
						<!-- contact data -->
						<UserOrderDetail-Contact :order="order" class="mb-6" />
					</v-col>
				</v-row>

				<!-- if can pay: payment instructions -->
				<UserOrderDetail-PaymentInstructions :order="order" v-if="canPay" class="mb-6" />

				<!-- digital products -->
				<UserOrderDetail-DigitalItems :order="order" v-if="isPaid" class="mb-6" />

				<!-- delivery -->
				<template v-if="showDeliveryDetails">
					<UserOrderDetail-Delivery
						v-for="(delivery, i) of order.deliveries"
						:key="i"
						:order="order"
						:delivery="delivery"
						class="mb-6"
					/>
				</template>
			</template>
			<!-- summary -->
			<OrderSummary :order="order" />
		</div>
	</UserpanelLayout>
</template>

<style scoped>
.disabled {
	opacity: 0.5;
}
.disabled,
.disabled ::v-deep * {
	pointer-events: none !important;
}
</style>

