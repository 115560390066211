<script>
export default {
	lang: 'shop',
	props: {
		value: Boolean,
		review: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			loadingSubmit: false,
			loadingCanCreateReview: false,
			canCreateReview: false,
			iReview: {},
			validation: {},
			reviewsConfig: this.$srv('reviewsConfig'),
		}
	},
	watch: {
		value() {
			this.dialog = this.value
		},
		dialog(v) {
			this.$emit('input', this.dialog)
			if (this.review) this.iReview = { ...this.review }
			this.$nextTick(() => {
				if (v && this.isNew) this.checkIfCanCreateReview()
				else if (!v) this.iReview = {}
			})
		},
		'iReview.text'(value) {
			if (value) {
				if (value.match(/(\r\n|\n|\r)/gm)) {
					this.$nextTick(() => {
						this.iReview.text = value.replace(/(\r\n|\n|\r)/gm, ' ').trim()
					})
				} else if (value.match(/[\s]{2,}/g)) {
					this.$nextTick(() => {
						this.iReview.text = value.replace(/[\s]{2,}/g, ' ')
					})
				}
			}
		},
	},
	computed: {
		isNew() {
			return !this.iReview.id
		},
	},
	methods: {
		async loadProductReview() {
			await this.$shopApi.get({
				url: `/user/get-review-by-product`,
				loading: false,
				query: {
					sku: this.iReview.sku,
					ean: this.iReview.ean,
					sellerId: this.iReview.sellerId,
				},
				onSuccess: ({ data }) => {
					if (data.review) data.review.id = undefined
					this.$assignDeep(this.iReview, data.review || {})
				},
			})
		},
		async submit() {
			const method = this.isNew ? 'post' : 'put'
			const url = this.isNew ? '/user/reviews' : `/user/reviews/${this.iReview.id}`
			await this.$shopApi[method]({
				url,
				onValidation: ({ validation }) => {
					this.validation = validation
				},
				loading: (v) => (this.loadingSubmit = v),
				data: {
					...this.iReview,
					sku: this.iReview.sku,
					ean: this.iReview.ean,
					sellerId: this.iReview.sellerId,
				},
				onSuccess: async ({ data }) => {
					await this.loadProductReview()
					this.canCreateReview = false
					this.$emit('success', data)
				},
			})
		},
		async checkIfCanCreateReview() {
			this.loadingCanCreateReview = true
			await this.$shopApi.get({
				url: `/user/can-create-review`,
				loading: false,
				query: {
					sku: this.iReview.sku,
					ean: this.iReview.ean,
					sellerId: this.iReview.sellerId,
				},
				onSuccess: ({ data }) => {
					this.canCreateReview = data.canCreateReview
				},
			})
			await this.loadProductReview()
			this.loadingCanCreateReview = false
		},

		goToUserReviews() {
			this.$router.push({ name: 'user.reviews' })
		},
	},
	created() {
		this.dialog = this.value
	},
}
</script>

<template>
	<v-dialog v-model="dialog" width="400px" :fullscreen="$b.m">
		<v-card width="100%" height="100%" class="pa-6 align-center" tile>
			<div class="d-flex justify-end" style="position: sticky; top: 0">
				<Button icon color="black" @click="dialog = false" large>
					<v-icon>mdi-close</v-icon>
				</Button>
			</div>
			<div v-if="loadingCanCreateReview" class="d-flex justify-center align-center py-16">
				<v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
			</div>
			<div v-else-if="canCreateReview || !isNew">
				<Validator :validation="validation" keys-prefix="iReview">
					<v-form @submit.prevent="submit">
						<div class="d-flex align-center">
							<div class="mr-4 rounded overflow-hidden elevation-1">
								<Media :src="iReview.image" width="100px" height="100px" class="" />
							</div>
							<div class="font-2 font-weight-bold">
								{{ iReview.title }}
							</div>
						</div>
						<div class="mt-6 mb-1 font-weight-bold">Tu Puntuación</div>
						<div>
							<v-rating
								dense
								v-model="iReview.rating"
								:color="$vars.strongtext"
								background-color="#cececd"
								:size="35"
								half-increments
								hover
							></v-rating>
							<div class="v-text-field__details" v-if="validation['rating']">
								<div class="v-messages theme--light error--text" role="alert">
									<div class="v-messages__wrapper">
										<div class="v-messages__message">{{ validation['rating'] }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-3 mb-1 font-weight-bold">Tu Opinión</div>
						<div>
							<TextArea
								placeholder="Escribí tu opinión del producto"
								v-model="iReview.text"
								outlined
								persistent-hint
								hint="Tu opinion sera visible para otros clientes"
								rows="6"
								counter="1500"
							>
							</TextArea>
						</div>
						<div class="mt-6 d-flex justify-end">
							<Button color="cta" type="submit" :loading="loadingSubmit" :block="$b.m">
								{{ isNew ? 'Enviar Reseña' : 'Actualizar Reseña' }}
							</Button>
						</div>
					</v-form>
				</Validator>
			</div>
			<div v-else class="text-center">
				<h3 class="font-2 my-4" v-if="iReview.status === 'pending'">
					{{ reviewsConfig.reviewPendingMessage }}
				</h3>
				<h3 class="font-2 my-4" v-else-if="iReview.status === 'approved'">
					{{ reviewsConfig.reviewApprovedMessage }}
				</h3>
				<h3 class="font-2 my-4" v-else-if="iReview.status === 'rejected'">
					{{ reviewsConfig.reviewRejectedMessage }}
				</h3>
				<h3 class="font-2 my-4" v-else>
					{{ reviewsConfig.cannotCreateReviewMessage }}
				</h3>
				<Button v-if="$route.name != 'user.reviews'" text :color="$vars.link" @click="goToUserReviews"
					>Modifica tu reseña acá</Button
				>
			</div>
		</v-card>
	</v-dialog>
</template>
