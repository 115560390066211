<script>
import brandsLogos from '../brands-logos.js'
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
		paymentData() {
			return this.order.payments[0].data
		},
	},
}
</script>

<template>
	<div class="d-flex align-center">
		<div class="mr-2">
			<div class="brand-logo-cont">
				<Media :src="brandsLogos[paymentData.brand.code]" height="100%" width="100%" contain eager />
			</div>
		</div>
		<div class="px-4 font-1">
			<div class="pb-1 font-2 d-flex align-center">
				<b class="mr-2">{{ paymentData.plan.qty }}x </b>
				<PriceText
					:amount="paymentData.plan.installmentsAmount"
					zero-decimals
					:sup-decimals="false"
					:currency="order.currency.sign"
				/>
			</div>
			<div class="pb-1 grey--text text--darken-2 d-flex align-center" v-if="paymentData.plan.qty > 1">
				<span class="mr-2">{{ 'Total' | lang }}</span>
				<PriceText
					:amount="paymentData.plan.total"
					zero-decimals
					:sup-decimals="false"
					:currency="order.currency.sign"
				/>
			</div>
			<div class="pb-1 d-flex align-center">
				<v-icon left>mdi-credit-card</v-icon>
				<div>
					<b>{{ $lang('Tarjeta {brand}', { brand: paymentData.brand.name }) }}</b>
					{{ 'terminada en' | lang }}
					{{ paymentData.lastFourDigits }}
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.brand-logo-cont {
	width: 60px;
	height: 60px;
	padding: 16px;
	border-radius: 100%;
	background-color: #eee;
}
</style>
