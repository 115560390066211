<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		digitalItems() {
			return this.order.items.filter((item) => item.type == 'digital')
		},
	},
	methods: {
		download(item) {
			let { baseUrl } = this.$shopApi.defaults()
			let url = `${baseUrl}/download-order-digital-item/${this.order.id}/${item.id}`
			location.href = url.replace(/^\/+/, '/')
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('Descarga de productos digitales')" v-if="digitalItems.length">
		<div>
			<div v-for="item of digitalItems" :key="item.id" class="pb-4">
				<Button color="link" @click="download(item)" large text outlined height="80" class="text-left">
					<div class="d-flex align-center text-left">
						<v-icon class="mr-1" size="60">mdi-download-circle-outline</v-icon>
						<div>
							<div class="font-2">
								<b>{{ item.name }}</b>
							</div>
							<div v-if="item.variantName" class="font-1" style="text-transform: unset">
								<small>{{ item.variantName }}</small>
							</div>
							<div class="grey--text font-1">
								<small>{{ item.digital.real }}</small>
							</div>
						</div>
					</div>
				</Button>
			</div>
		</div>
	</CardLayout>
</template>
