<script>
export default {
	lang: 'shop',
	name: 'OrderListCard',
	props: {
		review: Object,
		edit: Boolean,
	},
	data() {
		return {
			rating: 0,
		}
	},
}
</script>

<template>
	<v-card
		class="d-flex flex-column flex-md-row align-md-center justify-md-space-between elevation-0 pa-4"
		style="border-bottom: 1px solid #cececd94"
	>
		<div class="d-flex align-center">
			<Media :src="review.image" style="width: 150px; height: 150px" class="mr-8" />
			<div>
				<div class="mb-2" style="max-width: 200px">
					{{ review.title }}
				</div>
				<v-rating
					dense
					:value="review.rating"
					:color="$vars.strongtext"
					background-color="#cececd"
					size="22"
					class="d-md-none"
					half-increments
					readonly
				></v-rating>
			</div>
		</div>
		<div v-if="edit" class="d-none d-md-block">
			<v-rating
				dense
				:value="review.rating"
				:color="$vars.strongtext"
				background-color="#cececd"
				half-increments
				size="30"
				readonly
			></v-rating>
		</div>
		<div>
			<Button @click="$emit('click-review-btn', review)" color="primary" :block="$b.m">
				{{ edit ? 'Modificar Reseña' : 'Dejar una Reseña' }}
			</Button>
		</div>
	</v-card>
</template>

<style scoped>
.error-vuetify {
	color: #9f0010;
	background-color: #ffe4d5;
}
.success-vuetify {
	color: #006000;
	background-color: #dcffd6;
}
.item-key {
	padding: 2px 6px;
	font-weight: bold;
	background-color: #f3f3f3;
	border-radius: 4px;
	display: inline-block;
}
.item-value {
	padding: 6px 6px;
}
</style>
