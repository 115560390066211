<script>
export default {
	props: {
		order: Object,
	},
	computed: {
		accounts() {
			return this.order.payments[0].data.accounts
		},
		message() {
			return this.order.payments[0].data.message
		},
	},
}
</script>

<template>
	<div>
		<div class="font-2">
			<v-alert class="pb-2" v-for="(account, index) of accounts" :key="index">
				<div class="pb-2">
					<b>{{ account.key }}</b>
				</div>
				<div>
					Banco/Entidad: <b>{{ account.bankName }}</b>
				</div>
				<div v-if="account.accountNumber">
					Cuenta: <b>{{ account.accountNumber }}</b>
				</div>
				<div>
					CBU: <b>{{ account.cbu }}</b>
				</div>
				<div v-if="account.alias">
					Alias: <b>{{ account.alias }}</b>
				</div>
				<div v-if="account.accountOwner">
					Titular: <b>{{ account.accountOwner }}</b>
				</div>
				<div v-if="account.taxId">
					CUIT: <b>{{ account.taxId }}</b>
				</div>
			</v-alert>
		</div>
		<div v-if="message" class="preline py-2">
			{{ message }}
		</div>
	</div>
</template>
