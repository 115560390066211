<script>
import { isComponent } from '@/v-shop/autoloader'
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		methodComponent() {
			let name = `UserOrderDetail-Payment-${this.order.payments[0].methodKey}`
			return isComponent(name) ? name : null
		},
		payment() {
			return this.order.payments[0]
		},
		isPaid() {
			return this.payment.paid
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('Detalle del pago')" v-if="payment">
		<div class="d-flex align-start" v-if="payment.methodKey == 'Void'">
			<v-icon left>mdi-currency-usd-off</v-icon>
			<span>No se requieren pagos en esta orden</span>
		</div>
		<template v-else>
			<div class="d-flex align-start">
				<Media :src="payment.optionImage" width="35" height="35" class="mr-3" />
				<div style="line-height: 35px">
					<b>{{ payment.optionTitle }}</b>
				</div>
			</div>
			<div class="py-2" v-if="payment.optionMessages">
				<div v-for="(item, i) of payment.optionMessages" :key="i" class="py-1 d-flex align-start">
					<v-icon class="mr-1 message-icon">mdi-chevron-right</v-icon>
					<div class="font-1">{{ item.text }}</div>
				</div>
			</div>
			<div class="pt-4" v-if="methodComponent">
				<v-divider class="mb-4" />
				<component :is="methodComponent" :order="order" />
			</div>
			<div class="pt-4" v-if="payment.paid || payment.refunded">
				<v-divider class="mb-4" />
				<div v-if="payment.paid && payment.externalReference" class="d-flex align-center py-1">
					<v-icon left>mdi-pound</v-icon>
					Código de pago {{ payment.externalReference }}
				</div>
				<div v-if="payment.paid" class="d-flex align-center py-1">
					<v-icon color="success" left>mdi-check-circle</v-icon>
					Tu pago se acreditó el día {{ payment.paidAt | datetime }}
				</div>
				<div v-if="payment.refunded" class="d-flex align-center py-1">
					<v-icon left>mdi-cash-refund</v-icon>
					Tu dinero fue devuelto el día {{ payment.refundedAt | datetime }}
				</div>
			</div>
			<div class="pt-4" v-else>
				<v-divider class="mb-4" />
				<div class="d-flex align-center py-1">
					<v-icon color="error" left>mdi-alert</v-icon>
					Tu compra se encuentra pendiente de pago
				</div>
			</div>
		</template>
	</CardLayout>
</template>

