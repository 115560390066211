<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
		delivery: Object,
	},
	data() {
		return { loading: true }
	},
	computed: {
		marketplaceMode() {
			return process.env.VUE_APP_MARKETPLACE_MODE
		},
		packageData() {
			return this.delivery?.packageData || {}
		},
		isPackage() {
			return !!this.packageData.packageCreatedAt
		},
		shouldCalculateEta() {
			return !this.isPackage && this.order.paymentStatus == 'pending'
		},
		statusInfo() {
			return this.order.statusesInfo.deliveriesMethods[this.delivery.id]
		},
		showEtaMessage() {
			return (
				this.delivery.status != 'delivered' &&
				this.delivery.status != 'returned' &&
				this.delivery.hasEta &&
				this.delivery.etaMessage
			)
		},
	},
	methods: {
		async refreshEta() {
			this.loading = true
			if (this.shouldCalculateEta) {
				await this.$shopApi.get({
					url: `/user/order/${this.order.id}/refresh-delivery-eta/${this.delivery.id}`,
					loading: false,
					onMessage: null,
					onSuccess: ({ data }) => {
						this.$assign(this.delivery, data.delivery)
					},
				})
			}
			this.loading = false
		},
	},
	mounted() {
		this.refreshEta()
	},
}
</script>

<template>
	<CardLayout>
		<template #title>
			<div class="d-flex align-center flex-wrap w100">
				<div class="d-flex align-center">
					<span>{{ $lang('Detalle de la entrega') }}</span>
					<div class="d-flex align-center grey lighten-3 rounded px-2 ml-3" v-if="marketplaceMode">
						<v-icon color="link" left small>mdi-storefront</v-icon>
						<b>{{ delivery.seller.name }}</b>
					</div>
				</div>
				<v-spacer />
				<div class="mt-2 mt-sm-0 ml-sm-3">
					<div class="px-2 rounded" :class="`${statusInfo.color} white--text`">
						{{ statusInfo.buyerName || statusInfo.name }}
					</div>
				</div>
			</div>
		</template>
		<v-progress-linear indeterminate color="link" v-if="loading" />
		<v-row v-else>
			<v-col cols="12" sm="6">
				<component
					:is="`DeliveryOptionContent-${delivery.methodKey}`"
					v-bind="{
						currency: order.currency.sign,
						option: delivery,
						showCost: false,
						showEta: false,
						showToAddressLine: true,
						showReceiver: true,
						showBeforePaymentMessages: order.paymentStatus == 'pending',
						showAfterPaymentMessages: order.paymentStatus != 'pending',
					}"
				/>
			</v-col>
			<v-col cols="12" sm="6" v-if="isPackage" style="overflow-wrap: anywhere">
				<div class="font-weight-bold pb-1">Tu paquete</div>
				<div v-if="showEtaMessage" class="d-flex align-center py-1">
					<v-icon small left>mdi-store-clock</v-icon>
					<span>{{ delivery.etaMessage }}</span>
				</div>
				<div v-if="delivery.carrierName" class="d-flex align-center py-1">
					<v-icon small left>mdi-truck-delivery</v-icon>
					<span>Transporte {{ delivery.carrierName }}</span>
				</div>
				<div v-if="packageData.trackingCode" class="d-flex align-center py-1">
					<v-icon small left>mdi-pound-box</v-icon>
					<span>Código de seguimiento {{ packageData.trackingCode }}</span>
				</div>
				<div v-if="packageData.trackingUrl" class="d-flex align-center py-1">
					<v-icon small left>mdi-web-box</v-icon>
					<span
						>Revisa el seguimiento de tu entrega ingresando aquí
						<a target="_blank" :href="packageData.trackingUrl">{{ packageData.trackingUrl }}</a></span
					>
				</div>
			</v-col>
		</v-row>
	</CardLayout>
</template>
