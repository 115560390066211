<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		items() {
			return this.order.discounts
				.filter((discount) => discount.triggerType == 'giftCard')
				.map((x) => ({ icon: 'mdi-wallet-giftcard', text: `${x.discountName} - ${x.triggerKey}` }))
				.concat(
					this.order.discounts
						.filter((discount) => discount.triggerType == 'coupon')
						.map((x) => ({ icon: 'mdi-ticket-percent', text: `CUPÓN ${x.discountName}` }))
				)
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('Cupón de descuento')" v-if="items.length">
		<div
			class="d-flex align-center"
			:class="{ 'pb-2': i < items.length - 1 }"
			v-for="(x, i) of items"
			:key="i"
		>
			<v-icon left>{{ x.icon }}</v-icon>
			<div class="font-1">{{ x.text }}</div>
		</div>
	</CardLayout>
</template>

<style></style>

