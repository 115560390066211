<script>
export default {
	lang: 'shop',
	data() {
		return {
			pendingReviews: [],
			doneReviews: [],
			reviewDialog: false,
			currentReview: null,
			loading: true,
			tab: 0,
		}
	},
	trackPageView: true,
	methods: {
		async loadPendingReviews() {
			await this.$shopApi.get({
				url: '/user/pending-reviews',
				loading: false,
				onSuccess: ({ data }) => (this.pendingReviews = data.reviews),
			})
		},
		async loadDoneReviews() {
			await this.$shopApi.get({
				url: '/user/reviews',
				loading: false,
				onSuccess: ({ data }) => (this.doneReviews = data.reviews),
			})
		},
		selectReview(review) {
			this.currentReview = review
			this.reviewDialog = true
		},
		async init() {
			this.loading = true
			await Promise.all([this.loadPendingReviews(), this.loadDoneReviews()])
			if (!this.pendingReviews.length && this.doneReviews.length) {
				this.tab = 1
			}
			this.loading = false
		},
	},
	watch: {
		reviewDialog(v) {
			if (!v) this.currentReview = null
		},
	},
	created() {
		this.init()
	},
}
</script>

<template>
	<UserpanelLayout :title="$lang('Mis Reseñas')">
		<div v-if="loading" class="d-flex align-center justify-center py-16">
			<v-progress-circular size="50" indeterminate />
		</div>
		<template v-else>
			<v-tabs v-model="tab" background-color="var(--base)">
				<v-tabs-slider color="primary"></v-tabs-slider>

				<v-tab v-for="item of ['Pendientes', 'Realizadas']" :key="item">
					{{ item }}
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item :key="0">
					<template v-if="pendingReviews.length">
						<ReviewCard
							v-for="(review, i) of pendingReviews"
							:key="i"
							:review="review"
							@click-review-btn="selectReview"
							class="mb-2"
						/>
					</template>
					<div v-else class="pa-8 d-flex flex-column justify-center align-center">
						No hay reseñas pendientes.
						<Button color="link" text @click="tab = 1"> Ver reseñas realizadas. </Button>
					</div>
				</v-tab-item>
				<v-tab-item :key="1">
					<template v-if="doneReviews.length">
						<ReviewCard
							v-for="(review, i) of doneReviews"
							:key="i"
							:review="review"
							edit
							@click-review-btn="selectReview"
							class="mb-2"
						/>
					</template>
					<div v-else class="pa-8 text-center">No hay reseñas realizadas.</div>
				</v-tab-item>
			</v-tabs-items>
		</template>
		<UserReviewDialog v-if="currentReview" v-model="reviewDialog" :review="currentReview" @success="init" />
	</UserpanelLayout>
</template>

<style></style>
