var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardLayout',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-center flex-wrap w100"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.$lang('Detalle de la entrega')))]),(_vm.marketplaceMode)?_c('div',{staticClass:"d-flex align-center grey lighten-3 rounded px-2 ml-3"},[_c('v-icon',{attrs:{"color":"link","left":"","small":""}},[_vm._v("mdi-storefront")]),_c('b',[_vm._v(_vm._s(_vm.delivery.seller.name))])],1):_vm._e()]),_c('v-spacer'),_c('div',{staticClass:"mt-2 mt-sm-0 ml-sm-3"},[_c('div',{staticClass:"px-2 rounded",class:((_vm.statusInfo.color) + " white--text")},[_vm._v(" "+_vm._s(_vm.statusInfo.buyerName || _vm.statusInfo.name)+" ")])])],1)]},proxy:true}])},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"link"}}):_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c(("DeliveryOptionContent-" + (_vm.delivery.methodKey)),_vm._b({tag:"component"},'component',{
					currency: _vm.order.currency.sign,
					option: _vm.delivery,
					showCost: false,
					showEta: false,
					showToAddressLine: true,
					showReceiver: true,
					showBeforePaymentMessages: _vm.order.paymentStatus == 'pending',
					showAfterPaymentMessages: _vm.order.paymentStatus != 'pending',
				},false))],1),(_vm.isPackage)?_c('v-col',{staticStyle:{"overflow-wrap":"anywhere"},attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"font-weight-bold pb-1"},[_vm._v("Tu paquete")]),(_vm.showEtaMessage)?_c('div',{staticClass:"d-flex align-center py-1"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-store-clock")]),_c('span',[_vm._v(_vm._s(_vm.delivery.etaMessage))])],1):_vm._e(),(_vm.delivery.carrierName)?_c('div',{staticClass:"d-flex align-center py-1"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-truck-delivery")]),_c('span',[_vm._v("Transporte "+_vm._s(_vm.delivery.carrierName))])],1):_vm._e(),(_vm.packageData.trackingCode)?_c('div',{staticClass:"d-flex align-center py-1"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-pound-box")]),_c('span',[_vm._v("Código de seguimiento "+_vm._s(_vm.packageData.trackingCode))])],1):_vm._e(),(_vm.packageData.trackingUrl)?_c('div',{staticClass:"d-flex align-center py-1"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-web-box")]),_c('span',[_vm._v("Revisa el seguimiento de tu entrega ingresando aquí "),_c('a',{attrs:{"target":"_blank","href":_vm.packageData.trackingUrl}},[_vm._v(_vm._s(_vm.packageData.trackingUrl))])])],1):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }