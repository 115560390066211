<script>
import { loadScript } from '@/utils/load-script'
const PROD_SCRIPT_SRC = 'https://ecommerce-modal.modo.com.ar/bundle.js'
const DEV_SCRIPT_SRC = 'https://ecommerce-modal.preprod.modo.com.ar/bundle.js'
const IFRAME_ID = 'modo-iframe'

export default {
	lang: 'shop',
	inject: ['refreshOrder', 'getAppMessageDialog'],
	props: {
		order: Object,
	},
	data() {
		return {
			loading: null,
			modalOpened: false,
		}
	},
	methods: {
		async startIframe() {
			return new Promise((resolve) => {
				document.getElementById(IFRAME_ID)?.remove()
				const iframe = document.createElement('iframe')
				iframe.src = 'about:blank'
				iframe.id = IFRAME_ID
				let styles = [
					`position: fixed`,
					`top: 0`,
					`left: 0`,
					`width: 100vw`,
					`height: 100vh`,
					`z-index: 999999`,
				]
				iframe.setAttribute('style', styles.join(';'))
				iframe.addEventListener('load', async () => {
					resolve(iframe)
				})
				document.body.appendChild(iframe)
			})
		},
		async showModal() {
			this.loading = true

			const response = await this.$shopApi.post({
				url: `/order/${this.order.id}/resolve-payment`,
				loading: false,
			})

			if (!response.success) {
				this.loading = false
				return false
			}

			const { checkoutData, productionMode } = response.data

			const modalObject = {
				qrString: checkoutData.qr,
				checkoutId: checkoutData.id,
				deeplink: {
					// cuando se pague desde mobile con un link
					url: checkoutData.deeplink,
					callbackURL: window.location.href,
					callbackURLSuccess: window.location.href,
				},
				onSuccess: () => {
					this.onModalResolved(true, 5000)
				},
				onFailure: () => {
					this.onModalResolved(false)
				},
				onCancel: () => {
					this.onModalResolved(true)
				},
				onClose: () => {
					this.onModalResolved(true)
				},
				// cuando se pague desde la web con qr
				callbackURL: window.location.href,
				refreshData: async () => {
					const response = await this.$shopApi.post({
						url: `/order/${this.order.id}/resolve-payment`,
						loading: false,
					})
					if (response.success) {
						const { checkoutData } = response.data
						return {
							checkoutId: checkoutData.id,
							qrString: checkoutData.qr,
							deeplink: checkoutData.deeplink,
						}
					} else {
						this.onModalResolved(true)
					}
				},
			}

			let _window
			let _doc

			if (this.$b.m) {
				_window = window
				_doc = document
			} else {
				const iframe = await this.startIframe()
				_window = iframe.contentWindow
				_doc = iframe.contentDocument || iframe.contentWindow.document
			}

			const bundleSrc = productionMode ? PROD_SCRIPT_SRC : DEV_SCRIPT_SRC
			await loadScript(bundleSrc, _doc)
			_window.ModoSDK.modoInitPayment(modalObject)

			this.modalOpened = true
			this.loading = false
			return true
		},
		onModalResolved(closeModal = false, delay = 0) {
			setTimeout(async () => {
				await this.refreshOrder()
				if (closeModal) {
					document.getElementById(IFRAME_ID)?.remove()
					this.modalOpened = false
				}
			}, delay)
		},
	},
	watch: {
		loading(value, oldValue) {
			if (value) {
				this.getAppMessageDialog().open({
					loading: true,
					title: 'Iniciando Pago con MODO',
					dialogId: 'modo-loading',
				})
			} else if (oldValue === true && !this.$b.m) {
				this.getAppMessageDialog().close('modo-loading')
			}
		},
	},
	async mounted() {
		let stkey = `modo-opened-${this.order.id}`
		let shown = this.$b.m ? window.localStorage.getItem(stkey) : false
		if (!shown) {
			shown = await this.showModal()
			this.$b.m && window.localStorage.setItem(stkey, shown)
		}
	},
	beforeDestroy() {
		document.getElementById(IFRAME_ID)?.remove()
	},
}
</script>

<template>
	<div>
		<div
			class="font-2"
			v-html="$lang('Para realizar el pago, <b>haz click en el siguiente botón</b>.')"
		></div>
		<div class="py-5">
			<Button color="success" @click="showModal()" :loading="loading" :disabled="modalOpened">
				{{ 'Pagar con Modo' | lang }}
			</Button>
		</div>
	</div>
</template>
