export function loadScript(src, doc) {
	doc = doc || document
	return new Promise(function (resolve, reject) {
		let shouldAppend = false
		let el = doc.querySelector('script[src="' + src + '"]')
		if (!el) {
			el = doc.createElement('script')
			el.type = 'text/javascript'
			el.async = true
			el.src = src
			shouldAppend = true
		} else if (el.hasAttribute('data-loaded')) {
			resolve(el)
			return
		}

		el.addEventListener('error', reject)
		el.addEventListener('abort', reject)
		el.addEventListener('load', function loadScriptHandler() {
			el.setAttribute('data-loaded', true)
			resolve(el)
		})

		if (shouldAppend) doc.head.appendChild(el)
	})
}

export function unloadScript(src, doc) {
	doc = doc || document
	const el = doc.querySelector('script[src="' + src + '"]')
	if (el) doc.head.removeChild(el)
}
