<script>
import { isComponent } from '@/v-shop/autoloader'
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		payment() {
			return this.order.payments[0]
		},
		componentName() {
			let name = `UserOrderDetail-PaymentInstructions-${this.order.payments[0].methodKey}`
			return isComponent(name) ? name : null
		},
		showCard() {
			return this.payment && (!!this.componentName || !!this.payment.expirationTime)
		},
	},
}
</script>

<template>
	<CardLayout :title="$lang('¿Cómo realizo el pago?')" v-if="showCard">
		<component v-if="componentName" :is="componentName" :order="order" />
		<div
			v-if="this.payment.expirationTime"
			class="font-1"
			v-html="
				$lang(
					'Te solicitamos que <b>realices el pago dentro de las {time} hs.</b> de haber confirmado tu compra. Luego de ese plazo tu orden será cancelada.',
					{ time: String(this.payment.expirationTime) }
				)
			"
		></div>
	</CardLayout>
</template>
