<script>
export default {
	lang: 'shop',
	data() {
		return {
			orders: [],
			loading: true,
		}
	},
	trackPageView: true,
	methods: {
		loadOrders() {
			this.$shopApi.get({
				url: '/user/orders',
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.orders = data.orders
				},
			})
		},
	},
	mounted() {
		this.loadOrders()
	},
}
</script>

<template>
	<UserpanelLayout>
		<div v-if="!loading && !orders.length" class="text-center py-16">
			<v-icon x-large color="grey lighten-1">mdi-emoticon-sad-outline</v-icon>
			<div class="font-weight-bold text-h5 mb-4">
				{{ 'No realizaste ninguna compra' | lang }}
			</div>
			<div class="mb-6 d-flex align-center justify-center">
				<v-icon color="grey lighten-1">mdi-cart-arrow-down</v-icon>
				<div class="ml-2 preline">
					{{ $langText('Agregá productos a tu carrito y\nrealizá tu primera compra!') }}
				</div>
			</div>
			<div>
				<Button class="primary" x-large @click="$shop.goShop()">
					{{ 'Ir a la Tienda' | lang }}
				</Button>
			</div>
		</div>
		<div v-else-if="loading" class="d-flex align-center justify-center py-16">
			<v-progress-circular size="50" indeterminate />
			<div class="ml-4">{{ 'Cargando tus compras' | lang }}</div>
		</div>
		<template v-else>
			<CardLayout
				v-for="order of orders"
				:key="order.id"
				:color="order.statusesInfo.title.color"
				class="mb-8"
			>
				<template #title>
					<div class="px-2 rounded" :class="`${order.statusesInfo.title.color} white--text`">
						{{ order.statusesInfo.title.buyerName || order.statusesInfo.title.name }}
					</div>
					<v-spacer />
					<Button :to="{ name: 'user.order-detail', params: { id: order.id } }" color="link" small>
						{{ $lang('Ver detalle') }}
					</Button>
				</template>
				<div>
					<div class="d-flex align-center">
						<div class="d-flex align-center">
							<v-icon class="mr-1">mdi-shopping</v-icon>
							<b>{{ order.isSimple ? 'Pedido' : 'Compra' }} #{{ order.code }}</b>
						</div>
						<div class="ml-4 d-flex align-center">
							<v-icon class="mr-1">mdi-clock</v-icon> {{ order.statusesAt.main.confirmed | datetime }}
						</div>
					</div>
					<div class="pt-4">
						<v-simple-table class="items-table">
							<tbody>
								<tr v-for="(item, i) of order.items" :key="i">
									<td style="width: 72px">
										<Media :src="item.image" img-class="item-image" width="60px" />
									</td>
									<td>
										{{ item.name }}
										<div v-if="item.variantName">{{ item.variantName }}</div>
										<div class="grey--text">{{ item.qty }} {{ item.unitMetric }}</div>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</div>
				</div>
			</CardLayout>
		</template>
	</UserpanelLayout>
</template>

<style lang="scss" scoped>
.items-table ::v-deep .item-image {
	border-radius: 8px;
	border: 1px solid #f0f0f0;
}
.items-table {
	tr {
		td {
			padding: 8px !important;
		}
	}
}
</style>
