<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'account',
	data() {
		return {
			user: {},
			loading: false,
			validation: {},
			btnState: null,
		}
	},
	computed: {
		userData: get('shop/user'),
	},
	methods: {
		async updateContactInfo() {
			const { firstname, lastname, phoneCountryId, phoneAreaCode, phoneNumber, contactEmail, idNumber } =
				this.user
			await this.$shopApi.put({
				url: '/user/user-update',
				loading: (visible) => (this.loading = visible),
				onValidation: ({ validation }) => (this.validation = validation),
				data: {
					firstname,
					lastname,
					phoneCountryId,
					phoneAreaCode,
					phoneNumber,
					contactEmail,
					idNumber,
				},
				done: ({ success, data }) => {
					if (success) {
						this.btnState = 'success'
						this.$store.set('shop/user', data.user)
					} else {
						this.btnState = 'error'
					}
				},
			})
		},
	},
	created() {
		this.user = {
			firstname: this.userData.firstname,
			lastname: this.userData.lastname,
			contactEmail: this.userData.contactEmail,
			phoneCountryId: this.userData.phoneCountryId,
			phoneAreaCode: this.userData.phoneAreaCode,
			phoneNumber: this.userData.phoneNumber,
			idNumber: this.userData.idNumber,
		}
	},
}
</script>

<template>
	<form @submit.prevent="updateContactInfo">
		<Validator :validation="validation">
			<v-row>
				<v-col cols="12" md="4">
					<TextField
						:label="$lang('Nombre')"
						v-model="user.firstname"
						:disabled="userData.fixedIdentityFields"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<TextField
						:label="$lang('Apellido')"
						v-model="user.lastname"
						:disabled="userData.fixedIdentityFields"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<TextField
						:label="$lang('Nro. documento')"
						v-model="user.idNumber"
						:disabled="userData.fixedIdentityFields"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<TextField :label="$lang('Email de Contacto')" v-model="user.contactEmail" />
				</v-col>

				<v-col cols="12" md="3">
					<CountryPhoneCodeSelector v-model="user.phoneCountryId" />
				</v-col>
				<v-col cols="12" md="2">
					<TextField :label="$lang('Cód. Área')" v-model="user.phoneAreaCode" />
				</v-col>
				<v-col cols="12" md="3">
					<TextField :label="$lang('Teléfono')" v-model="user.phoneNumber" />
				</v-col>
				<v-col cols="12" md="12" class="text-right">
					<MagicButton @click="updateContactInfo" v-model="btnState" large :loading="loading">
						{{ 'Guardar Cambios' | lang }}
						<template #success> <v-icon>mdi-check</v-icon> {{ 'Listo!' | lang }} </template>
						<template #error> {{ 'Oops! Revisa el formulario' | lang }} </template>
					</MagicButton>
				</v-col>
			</v-row>
		</Validator>
	</form>
</template>

<style></style>
