<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		payOrder() {
			this.$shopApi.post(`/order/${this.order.id}/resolve-payment`, {
				loading: (v) => (this.loading = v),
				onSuccess: ({ data, options }) => {
					if (data.initPoint) {
						options.redirect(data.initPoint)
					}
				},
			})
		},
	},
}
</script>

<template>
	<div>
		<div
			class="font-2"
			v-html="
				$lang(
					'Para realizar el pago, <b>haz click en el siguiente botón</b> y serás redirigido a la pantalla de pago.'
				)
			"
		></div>
		<div class="py-5">
			<Button color="success" :loading="loading" @click="payOrder">
				{{ 'Pagar con Mobbex' | lang }}
			</Button>
		</div>
	</div>
</template>
